import Logo from "./Logo";
import "./App.css";

import styles from "./app.module.css";
import { useEffect, useState } from "react";

function App() {
  const [height, setHeight] = useState();

  useEffect(() => {
    setHeight(window.innerHeight);

    const handleResize = () => {
      setHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setHeight]);

  return (
    <div className={styles.container} style={{ ...(!!height && { height }) }}>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.headerLogo}>
            <Logo />
          </div>
          {/* <div className={styles.headerCta}> */}
          {/* <a href="" className={styles.filledButton}>
              Contact us
            </a> */}
          {/* </div> */}
        </div>
        <div className={styles.masthead}>
          <h1 className={styles.mastheadTitle}>
            Helping societies build a better future through technology
          </h1>
          <a
            href="mailto: hello@yepale.co"
            className={styles.filledSecondaryButton}
          >
            Reach out
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
